import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type CountdownTimerProps = {
  startSeconds: number;
  className?: string;
};

const TimerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.small}`};
  border: 2px solid ${({ theme }) => theme.colors.green};
  background: ${({ theme }) => theme.colors.greenWithTransparency};
  border-radius: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.jumbo};
  width: auto;
`;

const Digit = styled.span`
  display: inline-block;
  width: 0.97ch;
  text-align: center;
`;

const Separator = styled.span`
  margin: 0 ${({ theme }) => theme.spacing.xxxsmall};
`;

const CountdownTimer: React.FC<CountdownTimerProps> = ({ startSeconds, className }) => {
  const [timeLeft, setTimeLeft] = useState(startSeconds);

  useEffect(() => {
    setTimeLeft(startSeconds);
  }, [startSeconds]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [startSeconds]);

  const minutes = String(Math.floor(timeLeft / 60)).padStart(2, '0');
  const seconds = String(timeLeft % 60).padStart(2, '0');

  return (
    <TimerWrapper className={className}>
      <Digit>{minutes[0]}</Digit>
      <Digit>{minutes[1]}</Digit>
      <Separator>:</Separator>
      <Digit>{seconds[0]}</Digit>
      <Digit>{seconds[1]}</Digit>
    </TimerWrapper>
  );
};

export default CountdownTimer;
