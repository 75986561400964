import React from 'react';
import styled, { useTheme } from 'styled-components';

import { ContentData } from 'app/ui/components/molecules/content-item-areas-wrapper';

import Typography from 'app/ui/components/atoms/typography';
import GroupBreakdown from 'app/ui/components/molecules/whiteboard-slide/group-breakdown';
import CountdownTimer from '../../countdown';

type LessonSectionBreakdownProps = {
  sectionData: ContentData;
  isSmallVariant?: boolean;
  isOverviewMode?: boolean;
  isPresenterView?: boolean;
};

const BreakdownWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const SectionTitleArea = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.focoltone};
  padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.medium}`};
  margin: ${({ theme }) => theme.spacing.medium} 0;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ContentWrapper = styled.div<{ $isSmallVariant?: boolean }>`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme, $isSmallVariant }) => $isSmallVariant ? theme.spacing.xsmall : 0};
  margin-top: ${({ theme }) => theme.spacing.small};
  max-width: calc(100% - ${({ theme }) => theme.spacing.jumbo});
  overflow: hidden;

  ${({ theme: { media } }) => `${media.large(() => `
        flex-wrap: wrap;
    `)}
  `};
`;

const Zone = styled.div<{ $isSmallVariant?: boolean, $isPresenterView?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};

  ${({ theme: { media }, $isSmallVariant, $isPresenterView }) => `${media.large(() => `
        width: ${$isSmallVariant && !$isPresenterView ? '100%' : '50%'};
    `)}
  `};
`;

const SlideInfo = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.bold};
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

const SlideNoteArea = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.jumbo};
  background: ${({ theme }) => theme.colors.veryGray};
  border-radius: ${({ theme }) => theme.spacing.medium};
  margin: ${({ theme }) => `${theme.spacing.small} 0`};
  z-index: 101;

  ${({ theme: { media, spacing } }) => `${media.large(() => `
        width: calc(50% - ${spacing.medium});
    `)}
  `};
`;

const SlideNote = styled(Typography)`
  ul {
    list-style-position: inside;
    padding-left: ${({ theme }) => theme.spacing.massive};
  }

  li {
    text-indent: -1.4rem;
  }
`;

const TopCountdownTimer = styled(CountdownTimer)`
  display: none;

  ${({ theme: { media } }) => `${media.medium(() => `
      display: inline-flex;
      position: absolute;
      top: 2rem;
      right: 2rem;
    `)}
  `};
`;

const LessonSectionBreakdown: React.FC<LessonSectionBreakdownProps> = ({
  sectionData,
  isSmallVariant,
  isOverviewMode,
  isPresenterView,
}) => {
  const theme = useTheme();

  const applySmallVariant = isSmallVariant || isPresenterView || isOverviewMode;

  const textVariant = applySmallVariant ? 'body3' : 'whiteboard1';

  console.log(applySmallVariant)

  return (
    <>
      <BreakdownWrapper>
        <SectionTitleArea>
          <SlideInfo variant={textVariant} color={theme.colors.black}>{sectionData.title}</SlideInfo>
          <SlideInfo variant={textVariant} color={theme.colors.black}>{sectionData.additionalInfo}</SlideInfo>
        </SectionTitleArea>

        {!isOverviewMode && (
          <SlideInfo variant={textVariant} color={theme.colors.white}>
            {sectionData.content}
          </SlideInfo>
        )}

        <ContentWrapper $isSmallVariant={applySmallVariant}>
          {sectionData?.groups?.map((group, groupIndex) => (
            <Zone key={`zone-${group.id}`} $isSmallVariant={applySmallVariant} $isPresenterView={isPresenterView}>
              <GroupBreakdown
                  group={group}
                  index={groupIndex + 1}
                  key={`group-${groupIndex}`}
                  isSingleGroup={(sectionData?.groups?.length || 0) <= 1}
                  isSmallVariant={applySmallVariant}
                  isOverviewMode={isOverviewMode}
                />
            </Zone>
          ))}
          {isPresenterView && sectionData?.notes && sectionData.notes.trim().length > 0 && (
            <SlideNoteArea>
              <Typography variant={textVariant} color={theme.colors.white}>Notes: </Typography>
              <SlideNote variant={textVariant} color={theme.colors.white} htmlContent={sectionData.notes}></SlideNote>
            </SlideNoteArea>
          )}
        </ContentWrapper>
      </BreakdownWrapper>
      {!isPresenterView && !isOverviewMode && <TopCountdownTimer startSeconds={sectionData.duration || 0} />}
    </>
  );
};

export default LessonSectionBreakdown;
