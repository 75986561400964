import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { Language } from 'app/types/graphql';

import WorldIcon from 'app/ui/components/atoms/icons/world';
import ChevronUp from 'app/ui/components/atoms/icons/chevron-up';
import ChevronDown from 'app/ui/components/atoms/icons/chevron-down';
import RoundTick from 'app/ui/components/atoms/icons/round-tick';

type LanguagePickerProps = {
  languages: Language[],
  preferredLanguage: Language | null,
  onChange: (language: Language) => void,
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 10;
`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.veryGray};
  color: ${({ theme: { colors } }) => colors.focoltone};
  padding: ${({ theme: { spacing: { xsmall, small } } }) => `${xsmall} ${small}`};
  border-radius: ${({ theme: { spacing } }) => spacing.medium};
  width: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  height: 2rem;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 2.75rem;
  left: -6rem;
  background-color: ${({ theme: { colors } }) => colors.veryGray};
  border-radius: ${({ theme: { spacing } }) => spacing.medium};
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 12rem;
`;

const DropdownItem = styled.li`
  padding: ${({ theme: { spacing: { small, medium } } }) => `${small} ${medium}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme: { typography: { xsmall } } }) => xsmall};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.focoltoneWithTransparencyHover};
  }

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme: { colors: { black } } }) => black};
  }
`;

const LanguageLabel = styled.span<{ $isOpen: boolean }>`
  color: ${({ $isOpen, theme: { colors: { focoltone, white } } }) => $isOpen ? focoltone : white};
`;

const LanguagePicker: React.FC<LanguagePickerProps> = ({ languages, preferredLanguage, onChange }) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const selectLanguage = (language: Language) => {
    onChange(language);
    setIsOpen(false);
  };

  const { colors: { focoltone, white } } = theme;

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        <WorldIcon color={isOpen ? focoltone : white} />
        <LanguageLabel $isOpen={isOpen}>{preferredLanguage?.code?.substring(0, 2) || ''}</LanguageLabel>
        {isOpen ?
          <ChevronUp color={isOpen ? focoltone : white} /> :
          <ChevronDown color={isOpen ? focoltone : white} />
        }
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {languages.map(language => (
            <DropdownItem key={language.code} onClick={() => selectLanguage(language)} >
              {language.name}
              {language.code === preferredLanguage?.code && <RoundTick color={white} />}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default LanguagePicker;
