import styled from 'styled-components';

const SlideWrapper = styled.div<{ $isPresenterView?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: ${({ theme: { spacing } }) => `${spacing.massive} ${spacing.massive} ${spacing.medium}`};
  z-index: 101;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.black};
  height: ${({ $isPresenterView }) => $isPresenterView ? 'calc(100vh - 3.5rem)' : '100vh'};
`;

export default SlideWrapper;
