interface Colors {
  focoltone: string;
  black: string;
  caparol: string;
  pascol: string;
  blackSwan: string;
  knop: string;
  firebreathingRed: string;
  lightGray: string;
  white: string;
  veryGray: string;
  paragraphGray: string;
  devilsGray: string;
  faqGrey: string;
  focoltoneWithTransparency: string;
  focoltoneWithTransparencyHover: string;
  green: string;
  greenWithTransparency: string;
}

interface Typography {
  poppins: string;
  light: string;
  bold: string;
  tiny: string;
  xsmall: string;
  small: string;
  medium: string;
  large: string;
  xlarge: string;
  semixxl: string;
  xxlarge: string;
  jumbo: string;
  massive: string;
  huge: string;
  humongous: string;
  lsMicro: string;
  lsXsmall: string;
  lsSmall: string;
  lsMedium: string;
  lsLarge: string;
  lsXlarge: string;
  lsJumbo: string;
  lsMassive: string;
  lsHumongous: string;
  lineHeight: string;
}

interface Breakpoints {
  small: number;
  medium: number;
  large: number;
  xl: number;
}

interface Media {
  small: (callback: (theme: Theme) => string) => string;
  medium: (callback: (theme: Theme) => string) => string;
  large: (callback: (theme: Theme) => string) => string;
  xl: (callback: (theme: Theme) => string) => string;
}

interface Spacing {
  xxxsmall: string;
  xxsmall: string;
  xsmall: string;
  smaller: string;
  small: string;
  semimedium: string;
  medium: string;
  large: string;
  xlarge: string;
  xxlarge: string;
  jumbo: string;
  massive: string;
  humongous: string;
  superhumongous: string;
  extreme: string;
}

export interface Theme {
  colors: Colors;
  spacing: Spacing;
  typography: Typography;
  breakpoints: Breakpoints;
  media: Media;
  scaleForHighRes: (value: string) => string;
}

export const theme: Theme = {
  colors: {
    focoltone: '#ffed00',
    black: '#000000',
    caparol: '#fff899',
    pascol: '#fff466',
    blackSwan: '#4d4d4d',
    knop: '#b2b2b2',
    firebreathingRed: '#fb0000',
    lightGray: '#C9C9C9',
    paragraphGray: '#e8e8e8',
    veryGray: '#333333',
    white: '#ffffff',
    devilsGray: '#666666',
    faqGrey: '#161616',
    focoltoneWithTransparency: '#ffed0066',
    focoltoneWithTransparencyHover: '#ffed0059',
    green: '#51D85E',
    greenWithTransparency: 'rgba(81, 216, 94, 0.20)',
  },
  spacing: {
    xxxsmall: '0.25rem',
    xxsmall: '0.375rem',
    xsmall: '0.5rem',
    smaller: '0.625rem',
    small: '0.75rem',
    semimedium: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
    xlarge: '1.25rem',
    xxlarge: '1.375rem',
    jumbo: '1.5rem',
    massive: '2rem',
    humongous: '2.25rem',
    superhumongous: '2.5rem',
    extreme: '3.5rem',
  },
  typography: {
    poppins: '"Poppins", sans-serif',
    light: '300',
    bold: '500',
    tiny: '0.875rem',
    xsmall: '1rem', // 1rem
    small: '1.125rem', // 1.125rem
    medium: '1.25rem', // 1.25rem
    large: '1.375rem', // 1.375rem
    xlarge: '1.5rem', // 1.5rem
    semixxl: '1.75rem',
    xxlarge: '2rem',
    jumbo: '2.25rem', // 2.25rem
    massive: '2.5rem', // 2.5rem
    huge: '3rem',
    humongous: '3.625rem', // 3.625rem
    lsMicro: '-0.125rem',
    lsXsmall: '-0.0625rem',
    lsSmall: '-0.0313rem',
    lsMedium: '0rem',
    lsLarge: '0.0094rem',
    lsXlarge: '0.0156rem',
    lsJumbo: '0.0313rem',
    lsMassive: '0.0625rem',
    lsHumongous: '1.25',
    lineHeight: '1.5',
  },
  breakpoints: {
    small: 0,
    medium: 551,
    large: 1025,
    xl: 1441,
  },
  media: {
    small: (callback: (theme: Theme) => string) => `
      @media (min-width: ${theme.breakpoints.small}px) {
        ${callback(theme)}
      }
    `,
    medium: (callback: (theme: Theme) => string) => `
      @media (min-width: ${theme.breakpoints.medium}px) {
        ${callback(theme)}
      }
    `,
    large: (callback: (theme: Theme) => string) => `
      @media (min-width: ${theme.breakpoints.large}px) {
        ${callback(theme)}
      }
    `,
    xl: (callback: (theme: Theme) => string) => `
      @media (min-width: ${theme.breakpoints.xl + 1}px) {
        ${callback(theme)}
      }
    `,
  },
  scaleForHighRes: (value: string) => {
    return `${(parseFloat(value) / 1440) * 100}vw`;
  },
};
