import {
  SectionExerciseGroup,
  SectionExercise,
  LessonSection as WorkoutSection,
} from 'app/types/graphql';

import { lengthFromExercise } from 'app/utils/duration-handler';
import { Equipment } from 'types/graphql';

export type VideoData = {
  src: string,
  duration: string,
  name: string,
  thumbnail?: string,
  key?: string,
};

export type WorkoutType = {
  id: string,
  name: string,
  trainers: string,
  duration: string,
  concept: string,
  intensity: string,
  sections: WorkoutSection[],
  imageUrl: string,
  description: string,
  equipment: Equipment[],
  isCompleted: boolean,
  isSaved: boolean,
};

type GetVideoDataProps = {
  focusedContentItem: string | null,
  exercises: SectionExercise[],
};

export const findSectionById = (sections: WorkoutSection[], sectionId: string) => (
  sections.find((element: any) => element.id === sectionId)
);

export const flattenSectionsToExercises = (sections: WorkoutSection[]): SectionExercise[] => {
  return sections.reduce((accumulatedExercises: SectionExercise[], section: WorkoutSection) => {
    const sectionExercises: SectionExercise[] = (section.sectionExerciseGroups || []).reduce((groupExercises: SectionExercise[], group: SectionExerciseGroup) => {
      return [...groupExercises, ...(group.sectionExercises || [])];
    }, []);
    return [...accumulatedExercises, ...sectionExercises];
  }, []);
};

type SectionExerciseWithinGroup = {
  sectionExercise: SectionExercise | null,
};

export const findSectionExerciseInSection = (
  section: WorkoutSection | null,
  sectionExerciseId?: string | null,
): SectionExercise | null => {
  if (!section || !section?.sectionExerciseGroups) {
    return null;
  }

  if (!sectionExerciseId) {
    return section.sectionExerciseGroups?.[0]?.sectionExercises?.[0] || null;
  }

  const result = section.sectionExerciseGroups.reduce((search: SectionExerciseWithinGroup | null, group: SectionExerciseGroup) => {
    if (search) {
      return search;
    }

    const sectionExercise = group?.sectionExercises?.find(sectionExercise => sectionExercise.id === sectionExerciseId);

    if (sectionExercise) {
      return { sectionExercise };
    }

    return null;
  }, null);
  
  return result?.sectionExercise || section.sectionExerciseGroups?.[0]?.sectionExercises?.[0] || null;
};

export const getVideoData = ({
  focusedContentItem,
  exercises,
}: GetVideoDataProps): VideoData | null => {
  if (focusedContentItem) {
    if (exercises?.length > 0) {
      const focusedExercise = exercises.find((exercise: SectionExercise) => (
        exercise.id === focusedContentItem
      ));

      if (focusedExercise?.exercise?.video) {
        const video = focusedExercise.exercise.video;
        return {
          name: focusedExercise.exercise.name,
          src: video.url || video.signedUrl,
          duration: lengthFromExercise(focusedExercise),
          thumbnail: video?.thumbnail?.url || video?.thumbnail?.signedUrl,
          key: `${focusedExercise.id}_video`,
        };
      }
    }
  }

  return null;
};

export const indexToLetter = (index: number): string => (
  String.fromCharCode(97 + index)
);
