import React, { useState, useRef, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import Typography from 'app/ui/components/atoms/typography';

type ExpandableTextProps = {
  text: string,
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TextWrapper = styled.div<{ $maxHeight: string }>`
  max-width: calc(100% - 60px);
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: ${({ $maxHeight }) => $maxHeight};
  list-style: inside;
`;

const ToggleButton = styled.span`
  color: ${({ theme }) => theme.colors.focoltone};
  cursor: pointer;
`;

const StyledText = styled(Typography)`
  ul {
    list-style-position: inside;
    padding-left: ${({ theme }) => theme.spacing.massive};
  }

  li {
    text-indent: -1.4rem;
  }
`;

const ExpandableText = ({ text }: ExpandableTextProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const textRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState('0px');

  useEffect(() => {
    if (textRef.current) {
      const fullHeight = `${textRef.current.scrollHeight}px`;
      if (expanded) {
        setMaxHeight(fullHeight);
      } else {
        setMaxHeight('1.4rem');
      }
    }
  }, [expanded, text]);

  const moreLabel = intl.formatMessage({ id: 'accordions.labels.more' });
  const lessLabel = intl.formatMessage({ id: 'accordions.labels.less' });

  return (
    <Container>
      <TextWrapper ref={textRef} $maxHeight={maxHeight}>
        <StyledText variant="caption" color={theme.colors.paragraphGray} htmlContent={text}></StyledText>
      </TextWrapper>
      <ToggleButton onClick={() => setExpanded(!expanded)}>
        {expanded ? lessLabel : moreLabel}
      </ToggleButton>
    </Container>
  );
};

export default ExpandableText;
