import React from 'react';
import { useTheme } from 'styled-components';

import Typography from 'app/ui/components/atoms/typography';

import SlideWrapper from 'app/ui/components/atoms/slides/slide-wrapper';
import SlideHeader from 'app/ui/components/atoms/slides/header';
import TitleWrapper from 'app/ui/components/atoms/slides/title-wrapper';
import {
  LogosWrapper,
  LargeHyroxLogo,
  LargePoweredByFiit,
} from 'app/ui/components/atoms/slides/slide-logos';

type LessonSlideWrapperProps = {
  lessonTitle: string;
  slideIndex: number;
  totalSlides: number;
  isPresenterView?: boolean;
  children: React.ReactNode;
};

const LessonSlideWrapper: React.FC<LessonSlideWrapperProps> = ({
  lessonTitle,
  slideIndex,
  totalSlides,
  isPresenterView,
  children,
}) => {
  const theme = useTheme();

  return (
    <SlideWrapper $isPresenterView={isPresenterView}>
      <SlideHeader>
        <TitleWrapper>
          <Typography variant="h2" color={theme.colors.white}>
            {lessonTitle}
          </Typography>
          <Typography variant="h4" color={theme.colors.lightGray}>
            {`${slideIndex}/${totalSlides}`}
          </Typography>
        </TitleWrapper>
      </SlideHeader>

      {children}

      <LogosWrapper>
        <LargeHyroxLogo />
        <LargePoweredByFiit />
      </LogosWrapper>
    </SlideWrapper>
  );
};

export default LessonSlideWrapper;
